//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
export default {
  data: () => ({
    menuItems: [
      { icon: "mdi-plus-circle-outline", title: "Nou", action: "goedit" },
      { icon: "mdi-exit-to-app", title: "Menu", action: "gomenu" },
    ],
    itemList: [],
  }),
  created() {},
  mounted() {
    this.getItemList();
  },
  methods: {
    goBack() {
      this.$router.push("/listitems00edit");
    },
    goMenu(action) {
      switch (action) {
        case "goedit":
          this.goEdit(0);
          break;
        case "gomenu":
          this.$router.push("/mainmenu");
          break;
      }
    },
    getStyle(itemId) {
      if (itemId == 0) {
        return "color: red";
      } else {
        return "color: black";
      }
    },
    getItemList() {
      axios
        .post(api.URL() + "/api/v1/gllistagarticles01_getbyid", {
          token: sessionStorage.getItem("Token"),
          docYear: parseInt(sessionStorage["sessDocYear"]),
          docId: parseInt(sessionStorage["sessDocId"]),
          docLineId: 0,
        })
        .then((response) => {
          this.itemList = response.data;
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    goEdit(docLineId) {
      sessionStorage["sessDocLineId"] = parseInt(docLineId);
      this.$router.push("/listitems01edit");
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close == true) {
          this.$router.push("/");
        }
      };
      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
